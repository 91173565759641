import React from "react"
import { Link, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
// import { convertToBgImage } from "gbimage-bridge"
// import BackgroundImage from "gatsby-background-image"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout>
        <Seo title="All posts" />
        <Bio />
        <p>No blog posts found.</p>
      </Layout>
    )
  }

  return (
    <Layout >
      <Seo title="All posts" />

      <section className="max-w-screen-lg m-auto mt-20 px-5 md:p-0">
        <h1 className="text-center p-3 mt-5 mb-4 text-2xl font-bold text-white">Blog</h1>
        {posts.map((post, index) => {
          const title = post.title

          const featuredImage = {
            data:
              post.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
            alt: post.featuredImage?.node?.alt || ``,
          }
          // console.log("featuredImage", featuredImage)

          // const image = getImage(featuredImage)
          // const bgImage = convertToBgImage(image)

          return (
            <div
              key={`_${index}`}
              className="flex max-w-2xl mb-3 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800"
            >
              <div className="w-2/3">
                <div className="p-4">
                  <div className="w-2/3 flex items-center justify-between">
                    <span className="text-sm font-light text-gray-600 dark:text-gray-400">
                      {post.date}
                    </span>
                  </div>

                  <div className="mt-2">
                    <Link
                      to={post.uri}
                      className="text-lg font-bold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline"
                    >
                      {parse(title)}
                    </Link>
                    <div className="text-sm mt-2 text-gray-600 dark:text-gray-300">
                      {parse(post.excerpt)}
                    </div>
                  </div>

                  <div className="flex items-center justify-between mt-1"></div>
                  <Link
                    to={post.uri}
                    className="ext-sm text-blue-600 dark:text-blue-400 hover:underline"
                  >
                    Read more
                  </Link>
                </div>
              </div>
              <div className="w-1/3 h-full">
                {featuredImage?.data && (
                  <Link to={post.uri}>
                    <GatsbyImage
                      image={featuredImage.data}
                      alt={featuredImage.alt}
                      className="object-cover w-full h-64"
                    />
                  </Link>
                )}
              </div>
            </div>
          )
        })}
      </section>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
